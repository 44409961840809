<template>
<div class="bg-image">
<div class="overlay"></div>
</div>
<div class="content-wrapper">
    <div v-if="!success && !next" class="content">
      <div class="title">Registrácia nového členstva</div>

      <div class="item" style="text-align: center;"><input type="text" placeholder="Email Klienta" name="search" v-model="email" style="margin-left: 10px; width: 250px;" @keyup.enter="load()"/><i @click="load()" class="fa fa-search search" aria-hidden="true"></i></div>
      <div v-if="client.email" style="margin-top:-20px;">
        <div class="item"><div class="subtitle"><h2>{{client.title}} {{client.name}} {{client.surname}}</h2></div></div>
        <div class="item"><div class="subtitle">Adresa: {{client.address}}, {{client.city}}, {{client.psc}}, {{client.state}}</div></div>
        <div class="item"><div class="subtitle">Datum Narodenia: {{client.birth}} </div></div>
        <div class="item"><div class="subtitle">Obciansky preukaz: {{client.op}} </div></div>
        <div class="item"><div class="subtitle">Kontakt: {{client.phone}}, {{client.email}}</div></div>
        <div class="photo_wrapper">
          <div v-if="client.photo" class="photo" :style="{'background-image': 'url(https://api.mffitness.sk'+client.photo+')', 'background-size': 'cover'}"></div>
        </div>
        <div class="low"></div>
        <div class="item cselect"><div class="subtitle">Členstvo:</div>
          <div class="select"  style="margin-left: 10px;">
              <select v-model="membership">
                  <option v-for="item in Object.keys(translate)" :value="item" :key="item">{{ translate[item] }}</option>
              </select>
              <div class="select_arrow">
              </div>
          </div>
        </div>
        <p>Pokračovaním súhlasím s <a href="/poriadok.pdf">vnútornym poriadkom</a></p>
        <div class="button" style="margin: 0 auto; margin-top: 25px;" @click="next_call()">Pokračovat</div>
      </div>
    </div>
    <div v-if="next && !success" class="content">
    <div v-if="loading">
      <div class="spinner"></div>
    </div>
    <div v-else>
      <div class="title">Registrácia členstva "{{translate[membership]}}" pre klienta "{{ client.name }} {{ client.surname }}"</div>
      <canvas
        ref="canvas"
        @mousedown="startDrawing"
        @mousemove="draw"
        @mouseup="stopDrawing"
        @mouseleave="stopDrawing"
        @touchstart="startDrawing"
        @touchmove="draw"
        @touchend="stopDrawing"
        @touchcancel="stopDrawing"
        width="700"
        height="330"
        class="canvas"
      ></canvas>
      <div style="text-align: center; margin: 0 auto; margin-top: 40px;">
        <div v-if="!isCanvasEmpty" class="button" style="display: inline; background:#f44336;" @click="clearCanvas()">Zmazat podpis</div>
        <div v-if="!isCanvasEmpty" class="button" style="display: inline;" @click="save()">Zavazne potvrdit</div>
      </div>
    </div>
    </div>
    <div v-if="success">
      <div class="content">
      <div class="title">Registrácia noveho clenstva dokoncena</div>
      <p style="text-align: center;">Clenstvo uspesne zaregistrovane, na email {{client.email}} vam pride zmluva o clenstve.</p>
      <div class="button" style="margin: 0 auto; margin-top: 25px;" @click="refresh()">Vratit sa</div>
      </div>
    </div>
</div>
</template>

<script>
import ClientService from '@/services/client'
import ContractService from '@/services/contract'
import '@vuepic/vue-datepicker/dist/main.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'NewContract',
  components: {
  },
  data: function () {
    return {
      client: {
      },
      email: "",
      clicked: false,
      success: false,
      next: false,
      membership: "",
      isDrawing: false,
      context: null,
      isCanvasEmpty: true,
      translate: {
        "CO" : "Mesačné členstvo 29,90€/mesiac",
        "COP" : "Polročné členstvo 24,90€/mesiac",
        "COY" : "Ročné členstvo 19,90€/mesiac"
      },
      contract: {},
      loading: false
    }
  },
  methods: {
    next_call() {
      if (!this.membership) {
        toast("Vyberte Členstvo", {
          autoClose: 5000,
        });
        return;
      }
      this.next = true;
      let me = this;
      setTimeout(() => {
        const canvas = me.$refs.canvas;
        me.context = canvas.getContext('2d');
        me.context.lineWidth = 2;
        me.context.lineCap = 'round';
        me.context.strokeStyle = '#000';
        me.clearCanvas();
      }, 100)
    },
    refresh() {
      window.location.reload();
    },
    clearCanvas() {
        const canvas = this.$refs.canvas;
        this.context.clearRect(0, 0, canvas.width, canvas.height);
        this.isCanvasEmpty = true;
    },
    checkIfDrawn() {
      const canvas = this.$refs.canvas;
      const imageData = this.context.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;

      this.isCanvasEmpty = !pixels.some((pixel, index) => {
        return index % 4 !== 3 || pixel !== 0;
      });
    },
    load() {
      if (!this.email) {
        toast("Zadajte email klienta", {
          autoClose: 5000,
        });
        return;
      }

      ClientService.getClient(this.email)
        .then(response => {
            if (!response.data.data) {
              toast("Klient nenájdeny", {
                autoClose: 5000,
              });
              this.client = {}
              this.membership = ""
              this.isCanvasEmpty = true
            } else {
              this.client = response.data.data;
              this.membership = ""
              this.isCanvasEmpty = true
            }
        })
        .catch(response => {
          console.log(response);
          if (response.response.data.errors)
              Object.values(response.response.data.errors).forEach((element) => toast(element, {
                              autoClose: 5000,
                            }));
          else
            toast(response.response.data.message, {
                          autoClose: 5000,
            })
        });

    },
    save() {
      this.loading = true;
      const canvas = this.$refs.canvas;
      canvas.toBlob((blob) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64data = reader.result;

          // Add Base64 string instead of Blob to the contract object
          this.contract["signature"] = base64data;
          this.contract["contract_name"] = this.membership;
          this.contract["client_id"] = this.client.id;

          if (!this.clicked) {
            this.clicked = true;

            ContractService.storeContract(this.contract)
              .then(() => {
                toast("Clenstvo úspešne vytvorene", {
                  autoClose: 5000,
                });
                this.success = true;
                this.clicked = false;
                this.loading = false;
              })
              .catch((error) => {
                if (error.response.data.errors)
                  Object.values(error.response.data.errors).forEach((element) =>
                    toast(element, {
                      autoClose: 5000,
                    })
                  );
                else
                  toast(error.response.data.message, {
                    autoClose: 5000,
                  });

                this.loading = false;
              });
          }
        };

        // Convert the Blob to a Base64 string
        reader.readAsDataURL(blob);

      }, 'image/png');
    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    getPointerPosition(event) {
      const rect = this.$refs.canvas.getBoundingClientRect();
      if (event.touches) {
        // For touch events
        return {
          x: event.touches[0].clientX - rect.left,
          y: event.touches[0].clientY - rect.top
        };
      } else {
        // For mouse events
        return {
          x: event.offsetX,
          y: event.offsetY
        };
      }
    },
    startDrawing(event) {
      event.preventDefault(); // Prevent default touch actions like scrolling
      const pos = this.getPointerPosition(event);
      this.isDrawing = true;
      this.context.beginPath();
      this.context.moveTo(pos.x, pos.y);
    },
    draw(event) {
      if (!this.isDrawing) return;
      event.preventDefault(); // Prevent default touch actions like scrolling
      const pos = this.getPointerPosition(event);
      this.context.lineTo(pos.x, pos.y);
      this.context.stroke();
    },
    stopDrawing() {
      if (!this.isDrawing) return;
      this.isDrawing = false;
      this.context.closePath();
      this.checkIfDrawn();
    }
  },
  mounted() {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.spinner {
  border: 18px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  margin: 150px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

a {
  color:black;
  text-decoration: none;
}

.canvas {
  display: block;
  width: 700px;
  height: 330px;
  border: 1px solid #000;
  cursor: crosshair;
  margin: 0 auto;
}

.bg-image {
  background-image: url('../assets/bg.jpg');
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100%;
  width: 100%;
}

.search {
  position: relative;
  font-size: 20px;
  margin-left: 10px;
  top: 3px
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
}

.content-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    height: auto;
    margin: 0;
    max-width: 950px;
}

.content {
  overflow: auto;
  width: auto;
  height: auto;
  min-height: 480px;
  margin: 0;
  background: #f5f5f5;
  box-shadow: 0px 0px 30px rgba(66,66,66, .27);

}
 .title {
  text-align: center;
 }

.client-info, .title {
  margin: 0 auto;
  padding-bottom: 20px;
}

.fas {
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

a {
  font-weight: 700;
  cursor: pointer;
}

a:hover {
  color: #369af1;
}

.client-info {
    width: auto;
}

.fas:hover {
    color: #369af1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

#photo {
  opacity: 0;

  width:250px;
  height:250px;
  cursor: pointer;
  position: absolute;
}

.preview {
  width:250px;
  height:250px;
}


.actions {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  text-decoration: none;
  cursor: pointer;
}



.client-info {
  display:         flex;
  flex-wrap:       wrap;
  justify-content: center;
}

.item {
  display: block;
  padding: 5px 10px;
  margin: 0 auto;
  text-align: left;
}


.photo {
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;

    width:250px;
    height:250px;

    border: 1px solid;
    border-color: black;
    cursor: pointer;
}

.photo_wrapper {
    float: right;
    margin-top: -180px;
    margin-left: 30px;
    padding-right:15px;
}

.button {
    width: 180px;
    background-color: #369af1; /* Adjust color */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    margin-left: 20px;
    margin-top: 30px;
    text-align: center;
}

.subtitle {
    display: inline-block;
    font-size: 16px;
}
input[type="text"] {
  width: 160px;
  padding: 11px;
  padding-left: 15px;
  margin: 0px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.select {
    position: relative;
    display: inline-block;
    width: 160px;

}

.select select {
        font-family: 'Open Sans', sans-serif;
        display: inline-block;
        width: 160px;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: white;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 13px;
        width: 160px;
        padding: 11px;
        padding-left: 15px;

}
.select select::-ms-expand {
        display: none;
}
.select select:hover{
    color: #000000;
    background: #e8e8e8;
}
.select select:disabled {
    opacity: 1;
    pointer-events: none;
}
.select_arrow {
    position: absolute;
    top: 18px;
    right: 16px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
    border-top-color: #000000;
}

input[type="submit"] {
  width: 200px;
  background-color: #369af1; /* Adjust color */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  margin-top: 50px;
}

input[type="submit"]:hover {
  background-color: #4ca3f0; /* Darker shade for hover effect */
}

.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
.cselect {
  margin-top: 27px;
}

@media (max-width:800px)  {
  .photo_wrapper {
      float: left;
      margin-top: 10px;
      margin-left: 10px;
      padding-right:15px;
  }
  .low {
      clear: both;
  }

  .cselect {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .button {
    margin: 0 auto;
    margin-top: 25px;
  }
}

</style>
