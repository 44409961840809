import axios from 'axios'

const apiClient = axios.create({
	baseURL: 'https://api.mffitness.sk/',
	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
})

export default {
	getClients() {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/clients')
	},
	getQrCodes() {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/qrCodes')
	},
	getClient(data) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/clients/'+data)
	},
	storeClient(client) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.put('/client', client)
	},
	createQrCode(qr) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.put('/createQrCode', qr)
	},
	deleteClient(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.delete('/clients/'+id)
	},
	deleteQrCode(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.delete('/qr/'+id)
	},
	updateClient(id, client) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.post('/clients/'+id, client)
	},
	storeNewClient(client) {
		return apiClient.put('/public-client', client)
	},
}
