import axios from 'axios'

const apiClient = axios.create({
	baseURL: 'https://api.mffitness.sk/',
	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
})

export default {
	getPayments() {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/payments')
	},
	getPayment(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/payments/'+id)
	},
	storePayment(payment) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.put('/payments', payment)
	},
	deletePayment(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.delete('/payments/'+id)
	},
	updatePayment(id, payment) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.post('/payments/'+id, payment)
	},
}
