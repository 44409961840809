<template>
<transition name="fade">
<div class="modal" v-show="isVisible">
  <div class="modal-content">

    <span class="actions" v-if="!newclient"><a class="fas" :href="'/client/'+client.id" target="_blank"><i class="fas fa-regular fa-folder-open"></i></a> | <i @click="deleteClient()" class="fas fa-trash-alt"></i> | <i @click="closeModal" class="fas fa-times"></i></span>
    <span class="actions" v-else><i @click="closeModal" class="fas fa-times"></i></span>
    <div class="title" v-if="!newclient"> Klient - {{client.name}} {{client.surname}} ({{client.id}})</div>
    <div class="title" v-else> Novy Klient</div>
    <div class="client-info">
      <div class="row">
        <div class="photo_wrapper">
          <div v-if="client.photo" class="photo" :style="{'background-image': 'url(https://api.mffitness.sk/'+client.photo+')', 'background-size': 'cover'}"><input type="file" id="photo" name="photo" @change="onFileChange" required><img class="preview" v-if="url" :src="url" /></div>
          <div v-else class="photo"><input type="file" id="photo" name="photo"  @change="onFileChange" required><img class="preview" v-if="url" :src="url" /></div>

          <div class="button" @click="save()">Ulozit</div>
        </div>
      </div>
      <div class="item"><div class="subtitle">Typ:</div>
        <div class="select">
            <select v-model="client.role">
                <option value="member">Clen</option>
                <option value="employe">Zamestnanec</option>
                <option value="admin">Spravca</option>
            </select>
            <div class="select_arrow">
            </div>
        </div>
      </div>
      <div class="item"><div class="subtitle">Titul:</div><input type="text" placeholder="Titul" v-model="client.title" required></div>
      <div class="item"><div class="subtitle">Pohlavie:</div>
        <div class="select">
            <select v-model="client.gender">
                <option value="male">Muz</option>
                <option value="female">Zena</option>
                <option value="other">Ine</option>
            </select>
            <div class="select_arrow">
            </div>
        </div>
      </div>
      <div class="row">
        <div class="item"><div class="subtitle">Meno:</div><input type="text" placeholder="Meno" v-model="client.name" required></div>
        <div class="item"><div class="subtitle">Priezvisko:</div><input type="text" placeholder="Priezvisko" v-model="client.surname" required></div>
        <div class="item"><div class="subtitle">Ulica:</div><input type="text" placeholder="Ulica" v-model="client.address" required></div>
      </div>
      <div class="row">
        <div class="item"><div class="subtitle">PSC:</div><input type="text" placeholder="PSC" v-model="client.psc" required></div>
        <div class="item"><div class="subtitle">Mesto:</div><input type="text" placeholder="Mesto" v-model="client.city" required></div>
        <div class="item"><div class="subtitle">Krajina:</div><input type="text" placeholder="Krajina" v-model="client.state" required></div>
      </div>
      <div class="row">
        <div class="item"><div class="subtitle">Telefon:</div><input type="text" placeholder="Telefon" v-model="client.phone" required></div>
        <div class="item"><div class="subtitle">Email:</div><input type="text" placeholder="Email" v-model="client.email" required></div>
        <div class="item"><div class="subtitle">Datum narodenia:</div><VueDatePicker v-model="client.birth" style="display: inline-block; width: 160px;" :enable-time-picker="false"></VueDatePicker></div>
      </div>
      <div class="row">
        <div class="item"><div class="subtitle">Cislo OP:</div><input type="text" placeholder="OP" v-model="client.op" required></div>
        <div class="item"><div class="subtitle">Identifikacia karty:</div><input type="text" placeholder="Identifikacia karty" v-model="client.card_id" required></div>

      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
import ClientService from '@/services/client'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'ClientModal',
  components: {
    VueDatePicker
  },
  data: function () {
    return {
      isVisible: false,
      client: {
      },
      newclient: false,
      url: null,
      file: null,
      image: null,
    }
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    deleteClient(){
      ClientService.deleteClient(this.client.id)
        .then(() => {
          toast("Klient úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.$emit('success');
          this.closeModal();
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
    openModal(client) {
      this.client = structuredClone(client);
      this.isVisible = true;
      this.url = null;
      this.file = null;
      this.image = null;
      console.log(this.client.name)
      if (!this.client.name)
        this.newclient = true
    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    save() {
      this.client["birth"] = this.formatDate(this.client["birth"])

      if (!this.image && this.newClient) {
        toast("Nahrajte validnu fotku", {
          autoClose: 5000,
        });
        return;
      }

      if (this.image)
        this.client["photo"] = this.image;

      if (this.newclient) {
          ClientService.storeNewClient(this.client)
            .then(() => {
              toast("Klient úspešne vytvorený", {
                            autoClose: 5000,
              });
              this.$emit('success');
              this.closeModal();
            })
            .catch(response => {
              console.log(response);
              if (response.response.data.errors)
                  Object.values(response.response.data.errors).forEach((element) => toast(element, {
                                  autoClose: 5000,
                                }));
              else
                toast(response.response.data.message, {
                              autoClose: 5000,
                })
            });
      } else {

        ClientService.updateClient(this.client.id, this.client)
          .then(() => {
            toast("Klient upravený ", {
                          autoClose: 5000,
            });
            this.$emit('success');
            this.closeModal();
          })
          .catch(error => {
              if (error.response.data.errors)
                  Object.values(error.response.data.errors).forEach((element) => toast(element, {
                                  autoClose: 5000,
                                }));
              else
                toast(error.response.data.message, {
                              autoClose: 5000,
                })
          });

      }
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(this.file);
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = e => {
          this.image = e.target.result
      }

    }
  },
  mounted () {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.fas {
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

a {
  font-weight: 700;
  cursor: pointer;
}

a:hover {
  color: #369af1;
}

.client-info {
    width: 1320px;
}

.fas:hover {
    color: #369af1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

#photo {
  opacity: 0;

  width:250px;
  height:250px;
  cursor: pointer;
  position: absolute;
}

.preview {
  width:250px;
  height:250px;
}

.modal {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: absolute;
  background-color: #fefefe;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: fit-content;
  height: fit-content;
}

.actions {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  text-decoration: none;
  cursor: pointer;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.item {
  display: inline-block;
  margin: 0 20px;
  text-align: left;
}
.row {
    margin: 20px 0;
}

.photo {
    background: url("../../assets/upload.png");
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;

    width:250px;
    height:250px;

    border: 1px solid;
    border-color: black;
    cursor: pointer;
}

.photo_wrapper {
    float: right;
    margin-top: 1px;
    margin-left: 30px;
    padding-right:15px;
}

.button {
    width: 180px;
    background-color: #369af1; /* Adjust color */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
}

.subtitle {
    display: inline-block;
    width: 120px;
    margin-right: 20px;
    font-size: 13px;

}
input[type="text"] {
  width: 160px;
  padding: 11px;
  padding-left: 15px;
  margin: 0px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.select {
    position: relative;
    display: inline-block;
    width: 160px;

}

.select select {
        font-family: 'Open Sans', sans-serif;
        display: inline-block;
        width: 160px;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: white;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 13px;
        width: 160px;
        padding: 11px;
        padding-left: 15px;

}
.select select::-ms-expand {
        display: none;
}
.select select:hover{
    color: #000000;
    background: #e8e8e8;
}
.select select:disabled {
    opacity: 1;
    pointer-events: none;
}
.select_arrow {
    position: absolute;
    top: 18px;
    right: 16px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
    border-top-color: #000000;
}

input[type="submit"] {
  width: 200px;
  background-color: #369af1; /* Adjust color */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  margin-top: 50px;
}

input[type="submit"]:hover {
  background-color: #4ca3f0; /* Darker shade for hover effect */
}

.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
</style>
