import axios from 'axios'

const apiClient = axios.create({
	baseURL: 'https://api.mffitness.sk/',
	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}
})

export default {
	login(email, password) {
		return apiClient.post('/login', {"email":email, "password":password})
	},
	register(email, name, password) {
		return apiClient.post('/register', {"email":email, "name":name, "password":password})
	},
}
