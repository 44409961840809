<template>

  <AppMenu/>
  <ClientModal ref="client_modal" @open="openModal" @success="reload_data()"/>
  <ContractModal ref="contract_modal" @open="openModal" @success="reload_data()"/>
  <ConfirmModal ref="confirm_modal" @success="deleteContract(last_id, true)"/>
  <div class="content-wrapper">
      <div class="content">
          <div class="title"> Zmluvy <div style="float:right; width: 600px;">
            <VueDatePicker v-model="date" placeholder="Podpisy zmluv" range style="width: 370px; margin-top: 2px;display: block-inline; float: left; margin-right: 10px;" :enable-time-picker="false" format="MM/dd/yyyy"></VueDatePicker>
            <input type="text" placeholder="Vyhladavanie..." name="search" v-model="search"/> <i @click="openModal({}, 'contract')" class="fas-custom fas fa-file-circle-plus"></i></div></div>
          <table>
            <thead>
                <tr>
                    <th>Clen</th>
                    <th>Cislo zmluvy</th>
                    <th>Typ zmluvy</th>
                    <th>Podpis</th>
                    <th>Stav</th>
                    <th>Zaciatok</th>
                    <th>Koniec</th>
                    <th>Akcie</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item, key in paginatedData" :key="key">
                    <td><a @click="openModal(item, 'client')">{{item.client.name}} {{item.client.surname}} - {{item.client_id}}</a></td>
                    <td>{{item.contract_number}}</td>
                    <td>{{map[item.contract_name]}}</td>
                    <td>{{item.sign_date}}</td>
                    <td>{{state_translate[item.state]}}</td>
                    <td>{{item.start_date}}</td>
                    <td>{{item.end_date}}</td>
                    <td style="text-align: center;"><a class="fas" :href="'/contracts/'+item.id" target="_blank"><i class="fas fa-regular fa-folder-open"></i></a> | <i @click="openModal(item, 'contract')" class="fas fa-file-pen"></i> | <span  v-if="item.contract_file"><a :href="'https://api.mffitness.sk'+item.contract_file" target="_blank" class="fas"><i class="fas fa-file-pdf"></i></a> | </span><i class="fas fa-play" @click="updateContract(item, 1)"></i> | <i class="fas fa-pause" @click="updateContract(item, 2)"></i> | <i class="fas fa-stop" @click="updateContract(item, 3)"></i> | <i class="fas fa-trash-alt" @click="deleteContract(item.id)"></i></td>
                    </tr>
            </tbody>
        </table>
        <div class="pagination-controls">
          <a @click="prevPage" v-if="currentPage !== 1" class="pagination-button"><i class="fa-solid fa-angle-left"></i></a>
          <span class="pagination-info">Stranka {{ currentPage }} z {{ totalPages }} Zaznamov: {{filteredData.length}}</span>
          <a @click="nextPage" v-if="currentPage !== totalPages" class="pagination-button"><i class="fa-solid fa-angle-right"></i></a>
        </div>
      </div>
  </div>

</template>

<script>

import AppMenu from './AppMenu.vue'
import ClientModal from './Modals/ClientModal.vue';
import ContractModal from './Modals/ContractModal.vue';
import ConfirmModal from './Modals/ConfirmModal.vue';
import ContractService from '@/services/contract'
import ClientService from '@/services/client'
import { ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const date = ref();


export default {
  name: 'ContractList',
  components: {
    AppMenu,
    ClientModal,
    ContractModal,
    ConfirmModal,
    VueDatePicker,
  },
  data: function () {
    return {
      search: "",
      contracts: [
      ],
      map: {
        "CO" : "Mesačné členstvo",
        "COP" : "Polročné členstvo",
        "COY" : "Ročné členstvo"
      },
      state_translate : {
        "1": "Active",
        "2": "Paused",
        "3": "Canceled"
      },
      date: "",
      clients: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortOrder: 'desc',
      last_id: '',
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    openModal(item, modal) {
      if (modal == "client") {
          let client;
          this.clients.forEach((element) => {
            if (element["id"] == item.client_id) {
                client = element
              }
          });
          this.$refs.client_modal.openModal(client);
      }
      if (modal == "contract")
          this.$refs.contract_modal.openModal(item);
    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    reload_data(){
      ContractService.getContracts()
        .then(response => (this.contracts = response.data.data))
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
      ClientService.getClients()
        .then(response => (this.clients = response.data.data))
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
    updateContract(contract, state){
      contract["state"] = state
      ContractService.updateContract(contract.id, contract)
        .then(() => {
          toast("Kontrakt upravený na: "+this.state_translate[state], {
                        autoClose: 5000,
          });
          this.reload_data();
        })
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
    deleteContract(id, confirm=false){
      this.last_id = id
      if (!confirm)
        this.$refs.confirm_modal.openModal(id);
      else
      ContractService.deleteContract(id)
        .then(() => {
          toast("Kontrakt úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.reload_data();
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
  },
  mounted() {
      this.reload_data()
      date.value = [];
  },
  computed: {
    filteredData() {
      return this.contracts.filter(item => {
            if ((!this.date || this.date.length !== 2) && !this.search) {
             return true;
           }
          if (this.date) {
            const [startDate, endDate] = this.date;
            const date = this.formatDate(new Date(item.sign_date));

            if (date >= this.formatDate(startDate) && date <= this.formatDate(endDate))
              return true;
          }
          if (JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase()) && this.search)
              return true;
      });
    },
    sortedData() {
      // Create a new array by spreading `filteredData`
      const sortedArray = [...this.filteredData].sort((a, b) => {
        const dateA = new Date(a.sign_date);
        const dateB = new Date(b.sign_date);
        if (this.sortOrder === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      return sortedArray;
    },
    totalPages() {
      return Math.ceil(this.sortedData.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedData.slice(start, end);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

input[type="text"] {
  padding: 8px;
  padding-left: 15px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-right: 10px;
  margin-top: -1px;
}

a {
  font-weight: 700;
  cursor: pointer;
}

a:hover {
  color: #369af1;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin-bottom: 50px;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 8px;
    text-align: left;
}

thead {
    background-color: #79baf4;
}

tr:nth-child(even) {background: #dfdfdf}


.fas {
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
}

.fas-custom {
    padding:0;
    margin:0;
    font-size: 16px;
}

.fas:hover {
    color: #369af1;
}

</style>
