<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>



body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

.content-wrapper {
  position: absolute;
  width: calc(100% - 110px);
  height: 100%;
  top: 20px;
  left: 80px;
  max-height: calc(100% - 240px);
  min-height: calc(82%);
}

.content {
  overflow: auto;
  width: calc(100%-80px);
  height: 100%;
  background: #f5f5f5   ;
  box-shadow: 0px 0px 30px rgba(66,66,66, .27);
  padding: 40px;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}


.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-info {
  margin: 0 10px;
  font-size: 16px;
  color: #333333;
}

</style>
