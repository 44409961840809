<template>
<div class="bg-image">

  <div class="overlay"></div>

  <div class="login-container">

    <h1 class="headline">Welcome</h1><h1 class="headline2">to your fitness</h1><h1 class="headline3">journey</h1>


    <form action="/login" method="post"> <!-- Update action with your login processing script -->
      <div class="form-group">
        <input type="text" placeholder="Email" name="email" v-model="email" required>
      </div>
      <div class="form-group">
        <input type="password" placeholder="Heslo" name="password" v-model="password" required>
      </div>
      <div class="subtext">Užite si pohodlie online správy a kontroly vášho<br/> fitness členstva s jednoduchosťou a efektivitou!</div>
      <div class="form-group">
        <input type="button" @click="login()" value="Prihlasenie">
      </div>
    </form>
    <div class="bottom">
      <!-- <a href="#">Registracia</a> -->
    </div>
    <div v-if="errorMessage" class="error">{{errorMessage}}</div>

  </div>

</div>
</template>

<script>

import AuthService from '@/services/auth'


export default {
  name: 'UserLogin',
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      systeminfo: false,
    }
  },
  methods: {
    login() {
        let email = this.email
        let password = this.password
        let me = this
        if (!email || !password)
            return;
        AuthService.login(email, password)
            .then(response => {
              localStorage.setItem('token', response.data["token"])
              localStorage.setItem('role', response.data["role"])
              setTimeout(function() {
                      me.$router.push('/clients');
              }, 100)
            })
            .catch(response => (me.errorMessage = response.response.data.error))
    }
  },
  mounted () {
    if (localStorage.getItem('token')) {
      this.$router.push('/clients');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

/* Background image */
.bg-image {
  background-image: url('../assets/bg.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  height: 100%;
  width: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent overlay */
}


h1 {
  margin:0;
  padding: 0;
}

.error {
  font-size: 16px;
  color: #fcd1d1;
  font-weight: 700;
  position: absolute;
  bottom: -10px;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0px 0px 8px rgba(0,0,0,1);

}


/* Login container */
.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px; /* Adjust as necessary */
  padding: 20px;
  text-align: center;
}

/* Headline */
.headline {
  color: white;
  margin-bottom: 0px;
  margin-top: -10px;
  font-size: 80px;
  font-weight: 700;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
}
.headline2 {
  color: white;
  margin-bottom: 0px;
  margin-top: -5px;
  font-size: 40px;
  font-weight: 700;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
}

.headline3 {
    color: white;
    margin-bottom: 0px;
    margin-top: -35px;
    font-size: 100px;
    font-weight: 700;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    letter-spacing: -5px;
}

/* Form styling */
.form-group {
  margin-bottom: 15px;
  margin-top: 30px;
}

.subtext {
  color: white;
  font-weight: 400;
  text-shadow: 0px 0px 8px rgba(0,0,0,1);
  margin-top: 25px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

input[type="text"],
input[type="password"] {
  width: 70%;
  padding: 11px;
  padding-left: 15px;
  margin: -8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

input[type="button"] {
  width: 60%;
  background-color: #369af1; /* Adjust color */
  color: white;
  padding: 12px 20px;
  margin: -10px 0;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
}

input[type="button"]:hover {
  background-color: #4ca3f0; /* Darker shade for hover effect */
}

/* Secure login badge */
.secure-login-badge {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
}

.bottom {
  margin-top: 20px;
}

a {
  color: white;
  font-weight: 400;
  text-shadow: 0px 0px 8px rgba(0,0,0,1);
  font-size: 14px;
  text-decoration: none;
}

/* Responsive */
@media (max-width: 600px) {
  .login-container {
    width: 90%;
  }
}
</style>
