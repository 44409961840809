<template>
  <div>
    <canvas ref="qrCanvas"></canvas>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "QrCode",
  props: {
    text: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}), // Allow custom QR code options
    },
  },
  mounted() {
    this.generateQRCode();
  },
  watch: {
    text: "generateQRCode", // Re-generate the QR code if the text changes
  },
  methods: {
    async generateQRCode() {
      try {
        const canvas = this.$refs.qrCanvas;
        await QRCode.toCanvas(canvas, this.text, this.options);
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    },
  },
};
</script>
