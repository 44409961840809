import axios from 'axios'

const apiClient = axios.create({
	baseURL: 'https://api.mffitness.sk/',
	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
})

export default {
	getContracts() {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/contracts')
	},
	getContract(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/contracts/'+id)
	},
	storeContract(contract) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.put('/contract', contract)
	},
	deleteContract(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.delete('/contracts/'+id)
	},
	updateContract(id, contract) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.post('/contracts/'+id, contract)
	},
}
