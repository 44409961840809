<template>
<div class="bg-image">
<div class="overlay"></div>
</div>
<div class="content-wrapper">
  <div class="logo">
    <img src="@/assets/logo.png" alt="MF Fitness Logo" />
  </div>
    <div v-if="newRegistration" class="content">
      <div class="title">Registrácia nového klienta</div>
      <p>Po vyplnení a odoslaní tvojich údajov ich vo fitku spracujeme. Pri tvojej prvej návšteve nášho gymu si môžeš vybrať členstvo, podpísať ho a začať s nami cvičiť.</p>
      <div class="client-info">
        <div class="item"><div class="subtitle">Titul:</div><input type="text" placeholder="Titul" v-model="person.title"></div>
        <div class="item"><div class="subtitle">Pohlavie:</div>
          <div class="select">
              <select v-model="person.gender">
                  <option value="male">Muž</option>
                  <option value="female">Žena</option>
                  <option value="other">Iné</option>
              </select>
              <div class="select_arrow">
              </div>
          </div>
        </div>

        <div class="item"><div class="subtitle">Číslo OP:</div><input type="text" placeholder="Číslo OP" v-model="person.op" required></div>

        <div class="item"><div class="subtitle">Meno:</div><input type="text" placeholder="Meno" v-model="person.name" required></div>
        <div class="item"><div class="subtitle">Priezvisko:</div><input type="text" placeholder="Priezvisko" v-model="person.surname" required></div>
        <div class="item"><div class="subtitle">Ulica:</div><input type="text" placeholder="Ulica" v-model="person.address" required></div>

        <div class="item"><div class="subtitle">PSČ:</div><input type="text" placeholder="PSČ" v-model="person.psc" required></div>
        <div class="item"><div class="subtitle">Mesto:</div><input type="text" placeholder="Mesto" v-model="person.city" required></div>
        <div class="item"><div class="subtitle">Krajina:</div><input type="text" placeholder="Krajina" v-model="person.state" required></div>

        <div class="item"><div class="subtitle">Telefón:</div><input type="text" placeholder="Telefón" v-model="person.phone" required></div>
        <div class="item"><div class="subtitle">Email:</div><input type="text" placeholder="Email" v-model="person.email" required></div>
        <div class="item"><div class="subtitle">Narodenie:</div><VueDatePicker v-model="person.birth" style="display: inline-block; width: 160px;" :enable-time-picker="false"></VueDatePicker></div>
        <div style="clear:both;"></div>
        <label class="custom-checkbox">
            <input type="checkbox" id="checkbox1" v-model="person.gdpr">
            <span class="checkmark"></span>
            Súhlasím s <a href="/podmienky.pdf" target="_blank">Vyhlásením o ochrane osobných údajov</a>
        </label>
        <label class="custom-checkbox">
            <input type="checkbox" id="checkbox2" v-model="person.podmienky">
            <span class="checkmark"></span>
            Súhlasím so <a href="/podmienky.pdf" target="_blank">Všeobecnými obchodnými podmienkami</a>
        </label>
      </div>
      <div class="photo_wrapper" style="text-align: center;">
        <!--<div class="photo"><input type="file" id="photo" name="photo"  @change="onFileChange" required><img class="preview" v-if="url" :src="url" /></div>-->
        <label class="custom-file-upload" style="display: inline-block;">
        Nahrat Fotku<input type="file" class="upload" name="photo"  @change="onFileChange" required>
        </label>
        <div class="button" style="display: inline-block;" @click="save()">Odoslat</div>
        <span v-if="this.file" style="display: block;margin-top: 10px;">Vybrata fotka: {{ this.file.name }}</span>
      </div>

    </div>
    <div v-else>
      <div class="content">
      <div class="title">Registrácia nového klienta</div>
      <p>Boli ste úspešne zaregistrovaní! Pri najbližšej návšteve nášho gymu si môžete digitálne vybrať členstvo, podpísať ho a začať cvičiť s nami. Stačí nám povedať váš email: {{person.email}}</p>
      </div>
    </div>
</div>
</template>

<script>
import ClientService from '@/services/client'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'NewClient',
  components: {
    VueDatePicker
  },
  data: function () {
    return {
      person: {
      },
      url: null,
      file: null,
      image: null,
      clicked: false,
      newRegistration: true,
    }
  },
  methods: {
    save() {
      this.person["photo"] = this.image;
      if (!this.person["gdpr"])
          this.person["gdpr"] = ""
      if (!this.person["podmienky"])
          this.person["podmienky"] = ""
      if (!this.person["photo"]) {
        toast("Nahrajte validnu fotku", {
          autoClose: 5000,
        });
        return;
      }

      if (!this.clicked) {
          this.clicked = true;
          this.person["birth"] = this.formatDate(this.person["birth"])
          ClientService.storeNewClient(this.person)
            .then(() => {
                this.newRegistration = false;
                toast("Boli ste úspešne registrovaný", {
                              autoClose: 5000,
                });
            })
            .catch(response => {
              this.clicked = false;
              console.log(response);
              if (response.response.data.errors)
                  Object.values(response.response.data.errors).forEach((element) => toast(element, {
                                  autoClose: 5000,
                                }));
              else
                toast(response.response.data.message, {
                              autoClose: 5000,
                })
            });

      }
    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(this.file);
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = e => {
          this.image = e.target.result
      }

    }
  },
  mounted () {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


a {
  color:black;
  text-decoration: none;
}

.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
    margin: 10px 10px;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
    border: 1px solid black;
    transition: background-color 0.3s ease;
}

.custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #4CAF50;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.upload {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.logo {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 15px;
  margin-top: -40px;
}

.logo img {
  max-width: 100px;
}

.pagetitle {
    font-size: 55px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    padding:0;
    margin-top: -20px;
    color: #f5f5f5;
    text-shadow: 0px 0px 10px #000000;
}
/* Custom button styling */
.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s, border-color 0.3s;
    width: 180px;
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    margin: 0 auto;
    /* margin-top: 30px; */
    text-align: center;
}

.bg-image {
  background-image: url('../assets/bg.jpg');
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100%;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
}

.content-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    height: auto;
    margin: 0;
    max-width: 950px;
}

.content {
  overflow: auto;
  width: auto;
  height: auto;
  margin: 0;
  background: #f5f5f5;
  box-shadow: 0px 0px 30px rgba(66,66,66, .27);

}
 .title {
  text-align: center;
 }

.client-info, .title {
  margin: 0 auto;
  padding-bottom: 20px;
}

.fas {
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

a {
  font-weight: 700;
  cursor: pointer;
}

a:hover {
  color: #369af1;
}

.client-info {
    width: auto;
}

.fas:hover {
    color: #369af1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

#photo {
  opacity: 0;

  width:250px;
  height:250px;
  cursor: pointer;
  position: absolute;
}

.preview {
  width:250px;
  height:250px;
}


.actions {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  text-decoration: none;
  cursor: pointer;
}



.client-info {
  display:         flex;
  flex-wrap:       wrap;
  justify-content: center;
}

.item {
  float: left;
  display: block;
  padding: 10px 10px;
  margin: 0 auto;
  text-align: left;
}


.photo {
    background: url("../assets/upload.png");
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;

    width:150px;
    height:150px;

    border: 1px solid;
    border-color: black;
    cursor: pointer;
    margin:  0 auto;
    margin-top: 20px;
}

.photo_wrapper {

}

.button {
    width: 180px;
    background-color: #369af1; /* Adjust color */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    margin: 0 auto;
    /* margin-top: 30px; */
    text-align: center;
    margin-left: 20px;
}

.subtitle {
    display: inline-block;
    width: 90px;
    font-size: 13px;

}
input[type="text"] {
  width: 160px;
  padding: 11px;
  padding-left: 15px;
  margin: 0px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.select {
    position: relative;
    display: inline-block;
    width: 160px;

}

.select select {
        font-family: 'Open Sans', sans-serif;
        display: inline-block;
        width: 160px;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: white;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 13px;
        width: 160px;
        padding: 11px;
        padding-left: 15px;

}
.select select::-ms-expand {
        display: none;
}
.select select:hover{
    color: #000000;
    background: #e8e8e8;
}
.select select:disabled {
    opacity: 1;
    pointer-events: none;
}
.select_arrow {
    position: absolute;
    top: 18px;
    right: 16px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
    border-top-color: #000000;
}

input[type="submit"] {
  width: 200px;
  background-color: #369af1; /* Adjust color */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  margin-top: 50px;
}

input[type="submit"]:hover {
  background-color: #4ca3f0; /* Darker shade for hover effect */
}

.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
@media (max-width:600px)  {
    .button {
      margin: 10px 20px;
    }
}

</style>
