<template>

  <AppMenu/>
  <ClientModal ref="client_modal" @open="openModal" @success="reload_data()"/>
  <ContractModal ref="contract_modal" @open="openModal" @success="reload_data()"/>
  <div class="content-wrapper">
      <div class="content">
          <div class="title"> Členovia <div style="float:right;"><input type="text" placeholder="Vyhladavanie..." name="search" v-model="search"/> <i @click="openModal({contract:{}},'client')" class="fas-custom fas fa-user-plus"></i></div></div>
          <table>
            <thead>
                <tr>
                    <th>ID člena</th>
                    <th>Meno člena</th>
                    <th>Meno Poslednej Zmluvy</th>
                    <th>Cislo Poslednej Zmluvy</th>
                    <th>Akcie</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item, key in paginatedData" :key="key">
                    <td>{{item.id}}</td>
                    <td>{{item.name}} {{item.surname}}</td>
                    <td v-if="item.contract && item.contract.contract_name">{{item.contract.contract_name}} {{item.contract.contract_number}}</td>
                    <td v-else>No Contract</td>
                    <td v-if="item.contract && item.contract.id"><a @click="openModal(item.contract, 'contract')">{{item.contract.id}}</a></td>
                    <td v-else>No Contract</td>
                    <td style="text-align: center;"><a class="fas" :href="'/client/'+item.id" target="_blank"><i class="fas fa-regular fa-folder-open"></i></a> | <i @click="openModal(item, 'client')" class="fas fa-user-edit"></i> | <i class="fas fa-trash-alt" @click="deleteClient(item.id)"></i></td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-controls">
          <a @click="prevPage" v-if="currentPage !== 1" class="pagination-button"><i class="fa-solid fa-angle-left"></i></a>
          <span class="pagination-info">Stranka {{ currentPage }} z {{ totalPages }} Zaznamov: {{filteredData.length}}</span>
          <a @click="nextPage" v-if="currentPage !== totalPages" class="pagination-button"><i class="fa-solid fa-angle-right"></i></a>
        </div>
      </div>
  </div>

</template>

<script>

import AppMenu from './AppMenu.vue'
import ClientModal from './Modals/ClientModal.vue';
import ContractModal from './Modals/ContractModal.vue';
import ClientService from '@/services/client'
import ContractService from '@/services/contract'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'ClientList',
  components: {
    AppMenu,
    ClientModal,
    ContractModal,
  },
  data: function () {
    return {
      search: "",
      contract_translate: {
        "standard": "Standard",
        "standard_pro": "Standard Pro",
        "flex": "Flex",
      },
      contracts: [],
      clients: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortOrder: 'desc',
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    openModal(item, modal) {
      console.log(item)
      console.log("opening")
      if (modal == "client") {
          if (!item.contract)
            item["contract"] = {"contract_name": "", "contract_id": ""}
          this.$refs.client_modal.openModal(item);
      }
      if (modal == "contract")
          this.$refs.contract_modal.openModal(item);
    },
    deleteClient(id){
      ClientService.deleteClient(id)
        .then(() => {
          toast("Klient úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.reload_data();
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
    reload_data() {
      ClientService.getClients()
        .then(response => (this.clients = response.data.data))
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
      ContractService.getContracts()
        .then(response => (this.contracts = response.data.data))
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    }
  },
  mounted() {
    this.reload_data();
  },
  computed: {
    filteredData() {
      return this.clients.filter(item => {
          this.contracts.filter(contract => {

            if (item["id"] == contract["client_id"])
              item["contract"] = contract
          });
          if (JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase()))
              return true;
      });
    },
    sortedData() {
      const sortedArray = [...this.filteredData].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        if (this.sortOrder === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      return sortedArray;
    },
    totalPages() {
      return Math.ceil(this.sortedData.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedData.slice(start, end);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

input[type="text"] {
  padding: 8px;
  padding-left: 15px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-right: 10px;
  margin-top: -1px;
}

a {
  font-weight: 700;
  cursor: pointer;
}

a:hover {
  color: #369af1;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin-bottom: 50px;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 8px;
    text-align: left;
}

thead {
    background-color: #79baf4;
}

tr:nth-child(even) {background: #dfdfdf}


.fas {
    color: #369af1;
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
}

.fas-custom {
    padding:0;
    margin:0;
    font-size: 16px;
}

.fas:hover {
    color: #8f8f8f;
        color: #369af1;
}

</style>
