<template>

<transition name="fade">
<div class="modal" v-show="isVisible">
  <div class="modal-content">

    <span class="actions"><a class="fas" :href="'/contracts/'+contract.id" target="_blank"><i class="fas fa-regular fa-folder-open"></i></a> | <span  v-if="contract.contract_file"><a :href="'https://api.mffitness.sk'+contract.contract_file" target="_blank" class="fas"><i class="fa fa-file-text"></i></a> |</span> <span  v-if="!newContract"><i class="fas fa-trash-alt" @click="deleteContract(contract.id)"></i> |</span> <i @click="closeModal" class="fas fa-times"></i></span>
    <div class="title" v-if="!newContract"> Zmluva - {{client.name}} {{client.surname}} ({{contract.client_id}}) </div>
    <div class="title" v-else> Nova Zmluva</div>
    <div class="client-info">
      <div class="row">
        <div class="item"><div class="subtitle">Klient:</div>
          <div class="select select_wide">
              <select v-model="contract.client_id">
                  <option v-for="item in filteredData" :key="item" :value="item.id">{{item.name}} {{item.surname}} - {{item.op}}</option>
              </select>
              <div class="select_arrow">
              </div>
          </div>
        </div>
        <div class="item"><div class="subtitle">Vyhladaj klienta: </div><input type="text" placeholder="Hladaj klienta" name="client" v-model="search"></div>
      </div>
      <div class="row">
        <div class="item"><div class="subtitle">Zmluva:</div>
          <div class="select">
              <select v-model="contract.contract_name">
                  <option value="CO">Mesačné členstvo</option>
                  <option value="COP">Polročné členstvo</option>
                  <option value="COY">Ročné členstvo</option>
              </select>
              <div class="select_arrow">
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="item"><div class="subtitle">State:</div>
          <div class="select">
              <select v-model="contract.state">
                  <option value=1>Active</option>
                  <option value=2>Paused</option>
                  <option value=3>Stopped</option>
              </select>
              <div class="select_arrow">
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="item"><div class="subtitle">Datum podpisu:</div> <VueDatePicker v-model="contract.sign_date" :enable-time-picker="false" format="MM/dd/yyyy"/></div>
        <div class="item"><div class="subtitle">Datum zaciatku:</div><VueDatePicker v-model="contract.start_date" :enable-time-picker="false" format="MM/dd/yyyy"/></div>
        <div class="item"><div class="subtitle">Datum ukoncenia:</div><VueDatePicker v-model="contract.end_date" :enable-time-picker="false" format="MM/dd/yyyy"/></div>
      </div>
      <div class="row">
        <div class="button" @click="save()">Ulozit</div>
      </div>
    </div>
  </div>
</div>
</transition>

<ConfirmModal ref="confirm_modal" @success="deleteContract(true)"/>
</template>

<script>

import ClientService from '@/services/client'
import ContractService from '@/services/contract'

import ConfirmModal from './ConfirmModal.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'ContractModal',
  components: {
    VueDatePicker,
    ConfirmModal
  },
  data: function () {
    return {
      isVisible: false,
      contract: {},
      clients: [],
      client: {},
      search: "",
      newContract: false,
    }
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal(contract) {
      console.log(contract)
      this.contract = structuredClone(contract);
      this.isVisible = true;
      if (!this.contract.client_id)
        this.newContract = true
      else
        this.newContract = false
      let me = this
      if (this.contract.client_id) {
        this.clients.forEach((element) => {
          if (element["id"] == me.contract.client_id) {
              me.client = element
            }
        });
      }
    },
    loadClients(clients) {
      this.clients = clients

    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    save() {
      this.contract["start_date"] = this.formatDate(this.contract["start_date"])
      this.contract["end_date"] = this.formatDate(this.contract["end_date"])
      this.contract["sign_date"] = this.formatDate(this.contract["sign_date"])
      if (this.newContract) {
          ContractService.storeContract(this.contract)
            .then(() => {
              toast("Kontrakt úspešne vytvorený", {
                            autoClose: 5000,
              });
              this.$emit('success');
              this.closeModal();
            })
            .catch(error => {
                if (error.response.data.errors)
                    Object.values(error.response.data.errors).forEach((element) => toast(element, {
                                    autoClose: 5000,
                                  }));
                else
                  toast(error.response.data.message, {
                                autoClose: 5000,
                  })
            });
      } else {

        ContractService.updateContract(this.contract.id, this.contract)
          .then(() => {
            toast("Kontrakt upravený ", {
                          autoClose: 5000,
            });
            this.$emit('success');
            this.closeModal();
          })
          .catch(error => {
              if (error.response.data.errors)
                  Object.values(error.response.data.errors).forEach((element) => toast(element, {
                                  autoClose: 5000,
                                }));
              else
                toast(error.response.data.message, {
                              autoClose: 5000,
                })
          });

      }
    },
    deleteContract(id, confirm=false){
      if (!confirm)
        this.$refs.confirm_modal.openModal(this.contract.id);
      else
      ContractService.deleteContract(this.contract.id)
        .then(() => {
          toast("Kontrakt úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.$emit('success');
          this.closeModal();
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
  },
  mounted() {
    ClientService.getClients()
      .then(response => (this.loadClients(response.data.data)))
      .catch(() => {
        localStorage.setItem('token', "");
        this.$router.push('/');
      });

  },
  computed: {
    filteredData() {
      return this.clients.filter(item => {
          if (JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase()))
              return true;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.fas {
    color: #369af1;
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

.client-info {
    width: 1320px;
}

.fas:hover {
    color: #369af1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.modal {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: absolute;
  background-color: #fefefe;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: fit-content;
  height: fit-content;
}

.actions {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  text-decoration: none;
  cursor: pointer;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.item {
  display: inline-block;
  margin: 0 20px;
  text-align: left;
}
.row {
    margin: 20px 0;
}

.photo {
    background: url("../../assets/upload.png");
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;

    width:250px;
    height:250px;

    border: 1px solid;
    border-color: black;
    cursor: pointer;
}

.photo_wrapper {
    float: right;
    margin-top: 1px;
    margin-left: 30px;
    padding-right:15px;
}

.button {
    width: 180px;
    background-color: #369af1; /* Adjust color */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
}

.subtitle {
    display: inline-block;
    width: 120px;
    margin-right: 20px;
    font-size: 13px;

}
input[type="text"] {
  width: 160px;
  padding: 11px;
  padding-left: 15px;
  margin: 0px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.select {
    position: relative;
    display: inline-block;
    width: 160px;

}

.select_wide {
  position: relative;
  display: inline-block;
  width: 320px;
}



.select select {
        font-family: 'Open Sans', sans-serif;
        display: inline-block;
        width: 160px;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: white;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 13px;
        width: 160px;
        padding: 11px;
        padding-left: 15px;

}
.select_wide select {
  width: 320px;
}

.select select::-ms-expand {
        display: none;
}
.select select:hover{
    color: #000000;
    background: #e8e8e8;
}
.select select:disabled {
    opacity: 1;
    pointer-events: none;
}
.select_arrow {
    position: absolute;
    top: 18px;
    right: 16px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
    border-top-color: #000000;
}

input[type="submit"] {
  width: 200px;
  background-color: #369af1; /* Adjust color */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  margin-top: 50px;
}

input[type="submit"]:hover {
  background-color: #4ca3f0; /* Darker shade for hover effect */
}

.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
</style>
