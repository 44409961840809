<template>
  <AppMenu/>
  <div class="content-wrapper">
      <div class="content">
          <div class="title"> Klient - {{person.name}} {{person.surname}} ({{person.member_id}}) </div>
          <div class="client-info">
            <div class="row">
              <div class="photo_wrapper">
                <div class="photo" :style="{'background-image': person.photo}">
                <div class="button">Ulozit</div>
              </div>
            </div>
              <div class="item"><div class="subtitle">Typ:</div>
                <div class="select">
                    <select v-model="person.role">
                        <option value="member">Clen</option>
                        <option value="employe">Zamestnanec</option>
                        <option value="admin">Spravca</option>
                    </select>
                    <div class="select_arrow">
                    </div>
                </div>
              </div>
              <div class="item"><div class="subtitle">Titul:</div>
              <div class="select">
                  <select v-model="person.title">
                      <option value=""></option>
                      <option value="title1">Hello 2</option>
                      <option value="title2">Hello 3</option>
                      <option value="title3">Hello 4</option>
                  </select>
                  <div class="select_arrow">
                  </div>
              </div>
              </div>
              <div class="item"><div class="subtitle">Pohlavie:</div>
                <div class="select">
                    <select v-model="person.gender">
                        <option value="male">Muz</option>
                        <option value="female">Zena</option>
                        <option value="other">Ine</option>
                    </select>
                    <div class="select_arrow">
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="item"><div class="subtitle">Meno:</div><input type="text" placeholder="Meno" name="username" :value="person.name" required></div>
              <div class="item"><div class="subtitle">Priezvisko:</div><input type="text" placeholder="Priezvisko" name="username" :value="person.surname" required></div>
              <div class="item"><div class="subtitle">Ulica:</div><input type="text" placeholder="Ulica" name="username" :value="person.address" required></div>
            </div>
            <div class="row">
              <div class="item"><div class="subtitle">PSC:</div><input type="text" placeholder="PSC" name="username" :value="person.psc" required></div>
              <div class="item"><div class="subtitle">Mesto:</div><input type="text" placeholder="Mesto" name="username" :value="person.city" required></div>
              <div class="item"><div class="subtitle">Krajina:</div><input type="text" placeholder="Krajina" name="username" :value="person.state" required></div>
            </div>
            <div class="row">
              <div class="item"><div class="subtitle">Telefon:</div><input type="text" placeholder="Telefon" name="username" :value="person.phone" required></div>
              <div class="item"><div class="subtitle">Email:</div><input type="text" placeholder="Email" name="username" :value="person.email" required></div>
              <div class="item"><div class="subtitle">Datum narodenia:</div><input type="text" placeholder="Datum narodenia" name="username" :value="person.birth" required></div>
            </div>
            <div class="row">
              <div class="item"><div class="subtitle">Vek:</div><input type="text" placeholder="Vek" name="username" :value="person.age" required></div>
              <div class="item"><div class="subtitle">Clenske cislo:</div><input type="text" placeholder="Clenske cislo" name="username" :value="person.member_id" required></div>
              <div class="item"><div class="subtitle">Identifikacia karty:</div><input type="text" placeholder="Identifikacia karty" name="username" :value="person.card_id" required></div>
            </div>
            <div class="row">
              <div class="item"><div class="subtitle">Cislo OP:</div><input type="text" placeholder="Meno" name="username" :value="person.id" required></div>
              <div class="item"><div class="subtitle">Jazyk:</div><input type="text" placeholder="Meno" name="username" :value="person.lang" required></div>
              <div class="item"><div class="subtitle">Studio:</div>
                <div class="select">
                    <select v-model="person.studio">
                        <option value="studio1">studio1</option>
                        <option value="studio2">studio2</option>
                        <option value="studio3">studio3</option>
                    </select>
                    <div class="select_arrow">
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>

</template>

<script>

import AppMenu from './AppMenu.vue'

export default {
  name: 'ClientData',
  components: {
    AppMenu,
  },
  data: function () {
  return {
    person: {
      name: "Lukas",
      surname: "Balazik",
      role: "member",
      gender: "male",
      address: "Spodna 366/35",
      psc: "92528",
      city: "Puste Ulany",
      state: "Slovakia",
      phone: "0948608004",
      email: "lukasbalazik123@gmail.com",
      birth: "16.7.1997",
      age: "26",
      member_id: "123123123",
      card_id: "789798789",
      id: "SVK123123",
      lang: "SK",
      studio: "studio1",
      photo: "url('/photos/123123123.jpg')"
    }
  }
},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.item {
  display: inline-block;
  margin: 0 20px;
  text-align: left;
}
.row {
    margin: 20px 0;
}

.photo {
    background-repeat: no-repeat;
    background-size: cover;
    width:250px;
    height:250px;

    border: 1px solid;
    border-color: black;
}

.photo_wrapper {
    position: absolute;
    right: 140px;
    margin-top: 1px;
}

.button {
    width: 180px;
    background-color: #369af1; /* Adjust color */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    margin: 0 auto;
    margin-top: 285px;
    text-align: center;
}

.subtitle {
    display: inline-block;
    width: 120px;
    margin-right: 20px;
    font-size: 13px;

}
input[type="text"] {
  width: 200px;
  padding: 11px;
  padding-left: 15px;
  margin: 0px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.select {
    position: relative;
    display: inline-block;
    width: 200px;

}

.select select {
        font-family: 'Open Sans', sans-serif;
        display: inline-block;
        width: 200px;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: white;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 13px;
        width: 200px;
        padding: 11px;
        padding-left: 15px;

}
.select select::-ms-expand {
        display: none;
}
.select select:hover,
.select select:focus {
    color: #000000;
    background: #e8e8e8;
}
.select select:disabled {
    opacity: 1;
    pointer-events: none;
}
.select_arrow {
    position: absolute;
    top: 18px;
    right: 16px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
    border-top-color: #000000;
}

input[type="submit"] {
  width: 200px;
  background-color: #369af1; /* Adjust color */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  margin-top: 50px;
}

input[type="submit"]:hover {
  background-color: #4ca3f0; /* Darker shade for hover effect */
}

.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
</style>
