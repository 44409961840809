import axios from 'axios'

const apiClient = axios.create({
	baseURL: 'https://api.mffitness.sk/',
	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
})

export default {
	getEntries() {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/entries')
	},
	getEntry(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.get('/entries/'+id)
	},
	deleteEntry(id) {
		apiClient.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
		return apiClient.delete('/entries/'+id)
	},
}
