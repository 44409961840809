<template>

  <AppMenu/>
  <ClientModal ref="client_modal" @open="openModal" @success="reload_data()"/>

  <ContractModal ref="contract_modal" @open="openModal" @success="reload_data()"/>
  <div class="content-wrapper">
      <div class="content" v-if="client.id">
          <span class="actions"> <i @click="openModal(client, 'client')" class="fas fa-user-pen"></i> | <i class="fas fa-trash-alt" @click="deleteClient(client.id)"></i></span>

          <div class="title"> Klient: {{ client.title }} {{ client.name }} {{ client.surname }} ({{client.role}})</div>
          <h2>Informacie o členovi</h2>
          <table style="width: calc(100% - 300px); float:left;">
            <tbody>
                <tr>
                    <td>Meno</td>
                    <td>{{ client.name }} {{ client.surname }}</td>
                </tr>
                <tr>
                    <td>Trvale bydlisko</td>
                    <td>{{ client.address }}, {{ client.psc }}, {{ client.city }}, {{ client.state }}</td>
                </tr>
                <tr>
                    <td>Pohlavie</td>
                    <td>{{client.gender}}</td>
                </tr>
                <tr>
                    <td>Datum narodenia</td>
                    <td>{{client.birth}}</td>
                </tr>
                <tr>
                    <td>Kontakt</td>
                    <td>Email: {{client.email}}, Telefon: {{client.phone}}</td>
                </tr>
                <tr>
                    <td>Cislo obcianskeho preukazu</td>
                    <td>{{client.op}}</td>
                </tr>
                <tr>
                    <td>Cas vytvorenia:</td>
                    <td>{{client.created_at}}</td>
                </tr>
            </tbody>
        </table>
        <div style="float: right;">
          <div v-if="client.photo" class="photo preview" :style="{'background-image': 'url(https://api.mffitness.sk/'+client.photo+')', 'background-size': 'cover'}"></div>
        </div>

        <div style="clear:both;"></div>
          <h2> Zmluvy </h2>
        <div v-if="client.contracts && Object.keys(client.contracts).length !== 0" >
          <table>
            <thead>
                <tr>
                    <th>Cislo zmluvy</th>
                    <th>Typ zmluvy</th>
                    <th>Podpis</th>
                    <th>Stav</th>
                    <th>Zaciatok</th>
                    <th>Koniec</th>
                    <th>Akcie</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item, key in client.contracts" :key="key">
                    <td>{{item.contract_number}}</td>
                    <td>{{map[item.contract_name]}}</td>
                    <td>{{item.sign_date}}</td>
                    <td>{{state_translate[item.state]}}</td>
                    <td>{{item.start_date}}</td>
                    <td>{{item.end_date}}</td>
                    <td style="text-align: center;"><a class="fas" :href="'/contracts/'+item.id"><i class="fas fa-regular fa-folder-open"></i></a> | <i @click="openModal(item, 'contract')" class="fas fa-file-pen"></i> | <span  v-if="item.contract_file"><a :href="'https://api.mffitness.sk'+item.contract_file" target="_blank" class="fas"><i class="fas fa-file-pdf"></i></a> | </span><i class="fas fa-play" @click="updateContract(item, 1)"></i> | <i class="fas fa-pause" @click="updateContract(item, 2)"></i> | <i class="fas fa-stop" @click="updateContract(item, 3)"></i> | <i class="fas fa-trash-alt" @click="deleteContract(item.id)"></i></td>
                    </tr>
            </tbody>
        </table>
        </div>
        <div v-else>
          <p>Klient nema ziadne zmluvy</p>
        </div>
      </div>
  </div>

</template>

<script>

import AppMenu from './AppMenu.vue'
import ClientModal from './Modals/ClientModal.vue';
import ContractModal from './Modals/ContractModal.vue';
import ContractService from '@/services/contract'
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import ClientService from '@/services/client'


export default {
  props: ['id'],
  name: 'ContractDetails',
  components: {
    AppMenu,
    ClientModal,
    ContractModal,
  },
  data: function () {
    return {
      search: "",
      client: {},
      date: "",
      map: {
        "CO" : "Mesačné členstvo",
        "COP" : "Polročné členstvo",
        "COY" : "Ročné členstvo"
      },
      state_translate : {
        "1": "Active",
        "2": "Paused",
        "3": "Canceled"
      },
    }
  },
  methods: {
    openModal(item, modal) {
      if (modal == "client") {
          this.$refs.client_modal.openModal(item);
      }
      if (modal == "contract")
          this.$refs.contract_modal.openModal(item);

    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    reload_data(){
      ClientService.getClient(this.id)
        .then(response => (this.client = response.data.data))
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
    deleteClient(id){
      ClientService.deleteClient(id)
        .then(() => {
          toast("Klient úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.$router.push('/clients');
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
    updateContract(contract, state){
      contract["state"] = state
      ContractService.updateContract(contract.id, contract)
        .then(() => {
          toast("Kontrakt upravený na: "+this.state_translate[state], {
                        autoClose: 5000,
          });
          this.reload_data();
        })
        .catch(() => {
          localStorage.setItem('token', "");
          this.$router.push('/');
        });
    },
    deleteContract(id){
      ContractService.deleteContract(id)
        .then(() => {
          toast("Kontrakt úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.reload_data();
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
  },
  mounted() {
      this.reload_data()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.preview {
  width:250px;
  height:250px;
}

.photo {
    background: url("../assets/upload.png");
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;

    width:250px;
    height:250px;

    border: 1px solid;
    border-color: black;
    cursor: pointer;
}

.actions {
  color: #aaa;
  float: right;
  font-size: 22px;
  font-weight: bold;
}

input[type="text"] {
  padding: 8px;
  padding-left: 15px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-right: 10px;
  margin-top: -1px;
}

a {
  font-weight: 700;
  cursor: pointer;
  color: #2c3e50;
  text-decoration: none;
}

a:hover {
  color: #369af1;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 8px;
    text-align: left;
}

thead {
    background-color: #79baf4;
}

tr:nth-child(even) {background: #dfdfdf}


.fas {
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
}

.fas-custom {
    padding:0;
    margin:0;
    font-size: 16px;
}

.fas:hover {
    color: #369af1;
}

</style>
