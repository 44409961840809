<template>
<transition name="fade">
<div class="modal" v-show="isVisible">
  <div class="modal-content">

    <span class="actions"> <span  v-if="!newPayment"><i class="fas fa-trash-alt" @click="deletePayment(payment.id)"></i> |</span> <i @click="closeModal" class="fas fa-times"></i></span>
    <div class="title" v-if="!newPayment && payment.client"> Platba od {{payment.client.name}} {{payment.client.surname}} </div>
    <div class="title" v-if="!newPayment && !payment.client"> Jednorazova platba</div>
    <div class="title" v-if="newPayment"> Nova Platba</div>
    <div class="payment-info">
      <div class="row" v-if="payment.client">
        <div class="item"><div class="subtitle">Klient: {{payment.client.title}} {{payment.client.name}} {{payment.client.surname}}</div></div>
        <div class="item"><div class="subtitle">Zmluva: {{payment.contract.contract_number}} ({{payment.contract.sign_date}})</div></div>
        <div class="item"><div class="subtitle">Dlzka zmluvy: {{payment.contract.start_date}} {{payment.contract.end_date}}</div></div>
      </div>
      <div class="row" style="display: inline-block; margin-top: 0px;">
        <table style="margin-left: 30px;">
          <tr>
            <td>Platba:</td><td><input style="margin-left: 20px; width: 100px;" type="text" placeholder="Platba" v-model="payment.value" required> €</td>
          </tr>
          <tr>
            <td>Datum Platby:</td><td><VueDatePicker placeholder="Datum Platby" style="display:inline-block; margin-left: 20px; width: 250px;" v-model="payment.date" :enable-time-picker="false" format="MM/dd/yyyy"/></td>
        </tr>
        <tr>
          <td>Variabilny Symbol:</td><td><input style="margin-left: 20px;" type="text" placeholder="Variabilny Symbol" v-model="payment.contract.contract_number" required></td>
        </tr>
      </table>
      </div>
    </div>
    <div class="button" @click="save()">Ulozit</div>
  </div>
</div>
</transition>
</template>

<script>

import PaymentService from '@/services/payment'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'PaymentModal',
  components: {
    VueDatePicker
  },
  data: function () {
    return {
      isVisible: false,
      search: "",
      newPayment: false,
      payment: { contract: {}},
    }
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal(payment) {
      if (!payment["contract"])
        payment["contract"] = {}
      this.payment = structuredClone(payment);
      this.isVisible = true;
      if (!this.payment.id)
        this.newPayment = true
      else
        this.newPayment = false
    },
    formatDate(date) {
      if (!date) return null;
      date = new Date(date);
      const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return localDate.toISOString().split('T')[0];
    },
    save() {
      this.payment.contract_number = this.payment.contract.contract_number
      if (this.payment["date"])
        this.payment["date"] = this.formatDate(this.payment["date"])
      if (this.newPayment) {
          PaymentService.storePayment(this.payment)
            .then(() => {
              toast("Platba úspešne vytvorena", {
                            autoClose: 5000,
              });
              this.$emit('success');
              this.closeModal();
            })
            .catch(error => {
                if (error.response.data.errors)
                    Object.values(error.response.data.errors).forEach((element) => toast(element, {
                                    autoClose: 5000,
                                  }));
                else
                  toast(error.response.data.message, {
                                autoClose: 5000,
                  })
            });
      } else {

        PaymentService.updatePayment(this.payment.id, this.payment)
          .then(() => {
            toast("Platba upravena", {
                          autoClose: 5000,
            });
            this.$emit('success');
            this.closeModal();
          })
          .catch(error => {
              if (error.response.data.errors)
                  Object.values(error.response.data.errors).forEach((element) => toast(element, {
                                  autoClose: 5000,
                                }));
              else
                toast(error.response.data.message, {
                              autoClose: 5000,
                })
          });

      }
    },
    deletePayment(){
      PaymentService.deletePayment(this.payment.id)
        .then(() => {
          toast("Kontrakt úspešne zmazaný", {
                        autoClose: 5000,
          });
          this.$emit('success');
          this.closeModal();
        })
        .catch(() => {
          //localStorage.setItem('token', "");
          //this.$router.push('/');
        });
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.fas {
    color: #369af1;
    color: #8f8f8f;
    transition: color 0.3s ease;
    cursor: pointer;
    font-size: 20px;
}

.payment-info {
    width: 1320px;
}

.fas:hover {
    color: #369af1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.modal {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: absolute;
  background-color: #fefefe;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 500px;
  height: fit-content;
}

.actions {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  text-decoration: none;
  cursor: pointer;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.item {
  margin: 0 20px;
  text-align: left;
}
.row {
    margin: 20px 0;
}

.photo {
    background: url("../../assets/upload.png");
    background-size: 100px 100px;
    background-position: center center;
    background-repeat: no-repeat;

    width:250px;
    height:250px;

    border: 1px solid;
    border-color: black;
    cursor: pointer;
}

.photo_wrapper {
    float: right;
    margin-top: 1px;
    margin-left: 30px;
    padding-right:15px;
}

.button {
    width: 180px;
    background-color: #369af1; /* Adjust color */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 55px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
}

.subtitle {
    display: inline-block;
    margin-right: 20px;
    padding: 10px;
    font-size: 16px;

}
input[type="text"] {
  width: 160px;
  padding: 11px;
  padding-left: 15px;
  margin: 0px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}

.select {
    position: relative;
    display: inline-block;
    width: 160px;

}

.select_wide {
  position: relative;
  display: inline-block;
  width: 320px;
}



.select select {
        font-family: 'Open Sans', sans-serif;
        display: inline-block;
        width: 160px;
        cursor: pointer;
        padding: 10px 15px;
        outline: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: white;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 13px;
        width: 160px;
        padding: 11px;
        padding-left: 15px;

}
.select_wide select {
  width: 320px;
}

.select select::-ms-expand {
        display: none;
}
.select select:hover{
    color: #000000;
    background: #e8e8e8;
}
.select select:disabled {
    opacity: 1;
    pointer-events: none;
}
.select_arrow {
    position: absolute;
    top: 18px;
    right: 16px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
    border-top-color: #000000;
}

input[type="submit"] {
  width: 200px;
  background-color: #369af1; /* Adjust color */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
  margin-top: 50px;
}

input[type="submit"]:hover {
  background-color: #4ca3f0; /* Darker shade for hover effect */
}

.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
</style>
